
footer{
    display: flex;
    justify-content: space-between;
    background: url(./img/footer.png) no-repeat;
    background-size: cover;
    background-position: center;
    height: 600px;
    position: relative;
    .footerhead {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: url(./img/footerhead.svg) no-repeat;
        margin-top: -7px;
        padding-top: 50px;
    }
    &:before {
        content: '';
        width: 24%;
        height: 2px;
        background: #407788;
        top: 0;
        left: 0;
        position: absolute !important;
    }
    &:after {
        content: '';
        width: 24%;
        height: 2px;
        background: #407788;
        top: 0;
        right: 0;
        position: absolute !important;
    }
    .footer-navigation {
        display: flex;
        margin-left: -40px;
        gap: 15px;
    
        a {
            color: #fff;
            text-decoration: none;
            font-size: 16px;
            width: 125px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            background: url(./img/link.svg) no-repeat;
            transition: background-color 0.3s;
            transition: background 0.1s linear;
    
            &:hover{
                background: url(./img/link2.svg) no-repeat;
            }
            /*&.active{
                background: url(./img/link2.svg) no-repeat;
            }*/
            &.wp{
                background: url(./img/wp.svg) no-repeat;
                width: 158px;
                &:hover{
                    background: url(./img/wp2.svg) no-repeat;
                }
            }
        }
    }
    .footerimg {
        display: flex;
        img {
            margin: -80px auto 20px;
        }
    }
    .footerbottom {
        border-top: 1px solid #7EE8FF;
        padding-top: 20px;
        font-family: "Advent Pro", sans-serif;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
        display: flex;
        justify-content: space-between;
        .links {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            a{cursor: pointer;}
        }
        .copyright{cursor: default;}
    }
@media (max-width: 768px) {
    &:before,&:after {
        display: none;
    }
    flex-direction: column;
    height: 750px;
    background-position: right;
    .footerhead,
    .footer-navigation,
    .footerbottom,
    .links{
        flex-direction: column;
    }
    .footerhead{
        align-items: center;
        background: url(./img/m_footerhead.svg) no-repeat;
        background-size: contain;
        margin: 0;
        img{
            margin: 0 0 30px;max-width: 70%;
        }
    }
    .footer-navigation{
        margin: 0;
        align-items: center;
        gap: 10px;
    }
    .footerimg {
        justify-content: center;
        margin-bottom: 20px;
        img{
            margin: 0;
            max-width: 90%;
        }
    }
    .footerbottom{
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
        .links{align-items: center;}
        .copyright{padding-top: 20px;}
    }
}
}
