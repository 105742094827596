.referal{
    padding: 30px 0 130px;
    color: #fff;
    background-color: #141618;
    font-family: "Advent Pro", sans-serif;
    margin: 30px 0;
    h1,h2{
        font-family: "Unbounded", sans-serif;
    }

}