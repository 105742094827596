@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200;300;400;500;600;700;800&display=swap');

body {
    background-color: #000000;
    margin: 0;
    font-family: 'Advent Pro', sans-serif;
    font-family: 'Unbounded', sans-serif;
    color: #fff;
    overflow-x: hidden;
}

.container {
    width: 1350px;
    margin: 0 auto;
    max-width: 1350px;
    position: relative;
}

@media (max-width: 768px) {
.container{max-width: 370px;}
}
