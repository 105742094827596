.present{
    width: auto;
    height: 755px;
    margin-bottom: 200px;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 755px;
    }
    &:after {
        content: '';
        height: 200px;
        width: 100%;
        position: absolute;
        background: url(./img/present_line2.svg) no-repeat center center;
        margin-top: -130px;
    }
    .backgrounds {
        .bg_1{
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        }
        .bg_2 {
            transform-style: preserve-3d;
            background-image: radial-gradient(circle closest-side, #7ee8ff78, #b3285800);
            width: 40rem;
            height: 40rem;
            overflow: hidden;
            transform: scale3d(2, 2, 1);
            z-index: -1;
            filter: blur(100px);
            position: absolute;
            top: -10%;
            left: -50%;
            /* transform: translate(-50%, 0%);*/
        }
    }
    
    @media (max-width: 768px) {
        height: 500px;
        margin-bottom: 140px;
        .container{
            height:500px;
        }
        .backgrounds {
            .bg_1{
            top: auto;
            bottom: 100px;
            left: 0;
            transform: none;
            width: 100%;
        }
        .bg_2{display: none;}
        }
        &:after{display: none;}
    }
}
.present-content {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap:38px;
    .left {
        margin-top: 60px;
        font-weight: 700;
        font-size: 80px;
        line-height: 100px;
        text-transform: uppercase;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        .name {
            color: #7EE8FF;
        }
        .desc {
            color: #7EE8FF;
            margin-left: 61px;
            overflow: hidden;
            white-space: nowrap;
            width: 0;
            animation: typing 1.5s steps(30, end) forwards, blinking 1s infinite;
        }
        @keyframes typing {
            from { width: 0 }
            to { width: 100% }
          }
          
          @keyframes blinking {
            0% {border-color: transparent}
            50% {border-color: black}
            100% {border-color: transparent}
          }
    }
    .right {
        margin-top: 40px;
        font-weight: 300;
        font-size: 40px;
        line-height: 50px;
        color: #FFFFFF;
        background: url(./img/presenttext.svg) no-repeat;
        position: relative;
        .text {
            margin: 70px 0px 0px 30px;
            width: 540px;
            overflow: hidden;
            .text__top {
                opacity: 0;
                animation: TextTop 1.5s ease-in-out forwards;
                animation-delay: 0.5s;
              }
              
              .text__bottom {
                opacity: 0;
                animation: TextBottom 1.5s ease-in-out forwards;
                animation-delay: 0.5s;
            }
            @keyframes TextTop {
                0% {
                  opacity: 0;
                  clip-path: inset(100% 0 0 0); // Обрезка сверху
                  transform: translateY(10px);
                }
                40% {
                  opacity: 1;
                  clip-path: inset(0 0 0 0);
                  transform: translateY(0);
                }
                50% { opacity: 0.8; }
                60% { opacity: 1; }
                70% { opacity: 0.7; }
                80% { opacity: 1; }
                90% { opacity: 0.9; }
                100% { opacity: 1; }
              }
              
              @keyframes TextBottom {
                0% {
                  opacity: 0;
                  clip-path: inset(0 0 100% 0); // Обрезка снизу
                  transform: translateY(-10px);
                }
                40% {
                  opacity: 1;
                  clip-path: inset(0 0 0 0);
                  transform: translateY(0);
                }
                50% { opacity: 0.8; }
                60% { opacity: 1; }
                70% { opacity: 0.7; }
                80% { opacity: 1; }
                90% { opacity: 0.9; }
                100% { opacity: 1; }
              }
        }
        &::after{
            content: '';
            background: url(./img/present_line.svg) no-repeat;
            width: 716px;
            height: 63px;
            display: block;
            margin: -50px 0 0 -100px;
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0 30px;
        gap: 30px;
        align-items: flex-end;
        .left {
            font-size: 30px;
            line-height: 40px;
            margin-top: 10px;
            align-items: flex-end;
            width: 250px;
            .desc{
            }
        }
        .right {
            margin-top: 0;
            margin-right: 40px;
            .text {
                width: 100%;
                .text__top,
                .text__bottom{
                    font-size: 20px;
                    line-height: 30px;

                }
                .text__bottom{}
            }
            &::after{display: none;}
        }

    }
}
.present-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-family: 'Advent Pro';
    font-size: 24px;
    z-index: 9;
    width: 338px;
    bottom: -85px;
    left: calc(50% - 169px);
    position: absolute;
    .buy-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: url(./img/buybtn.svg) no-repeat;
        border: 0;
        width: 338px;
        height: 85px;
        color: #fff;
        transition: all 0.1s linear;
        &:hover {
            background: url(./img/buybtnhov.svg) no-repeat center center;
        }
    }
    .more{
        margin-top: 40px;
        line-height: 30px;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        .buy-btn{
            width: 260px;
            height: 66px;
            background-size: contain;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 1px;
            &:hover{
                background-size: contain;
            }
        }
        .more{
            margin-top: 15px;
            font-weight: 500;
            font-size: 20px;}
    }
}
.holding{
    .holding_box{
        display: flex;
        justify-content: space-between;
        height: 680px;
    }
    .btn_box{
        background: url(./img/btn_box.svg) no-repeat center center;
        width: 336px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: "Advent Pro";
        font-size: 24px;
        margin-top: 50px;
        animation: fadeInLeft 1.4s ease-in-out forwards;
    }
    .text_box{
        background: url(./img/text-back-about.svg) no-repeat center center;
        width: 561px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin-top: -120px;
        padding: 0 60px;
        font-size: 20px;
        font-weight: 300;
        animation: fadeInBg 1.4s ease-in-out forwards;
        z-index: 9;
    }
    .backgrounds {
        .bg_1{
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        }
        .bg_2 {
            z-index: -1;
            position: absolute;
            bottom: 25px;
            right: 100px;
        }
    }
    
    @media (max-width: 768px) {
        margin-bottom: 60px;
        .holding_box{
            flex-direction: column;
            justify-content: flex-start;
            height: 480px;
            justify-content: flex-start;
            align-items: flex-end;
        }
        .text_box{
            width: 336px;
            height: 190px;
            font-size: 14px;
            background-size: contain;
            padding: 0 20px 0 37px;
            margin:0 20px 0;
        }
        .btn_box{
            width: 240px;
            height: 60px;
            background-size: contain;
            margin: 30px 20px;
        }
        .backgrounds {
            .bg_1{
            top: auto;
            bottom: 0;
            left: 0;
            transform: none;
            width: 100%;
        }
        .bg_2{display: none;}
        }
    }

}

.token{
    margin-bottom: 120px;
    .token_box{
        display: flex;
        justify-content: space-between;
        height: 680px;
    }
    
    .text_box{
        background: url(./img/text-back-about_2.svg) no-repeat center center;
        width: 561px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin-top: -120px;
        padding: 0 60px;
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
    }
    .btn_box{
        background: url(./img/btn_box.svg) no-repeat center center;
        width: 336px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: "Advent Pro";
        font-size: 24px;
        margin-top: 50px;
    }
    .bg_1{
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .bg_2 {
        z-index: -1;
        position: absolute;
        top: 50%;
        left: -8%;
        transform: translate(-50%, 0%);
    }
    @media (max-width: 768px) {
        .token_box{
            flex-direction: column;
            justify-content: flex-start;
            height: 400px;
        }
        .text_box{
            width: 336px;
            height: 190px;
            font-size: 14px;
            background-size: contain;
            padding: 0 20px 0 37px;
            margin:0 20px 0;
            order: 2;
        }
        .btn_box{
            order:1;
            width: 240px;
            height: 60px;
            background-size: contain;
            margin: 30px 20px;
        }
        .bg_1{
            top: auto;
            bottom: -80px;
            left: 40px;
            transform: none;
            width: 85%;
        }
        .bg_2{display: none;}
    }
}
.rwa{
    margin-bottom: 120px;
    h2 {
        max-width: 300px;
        margin-bottom: 0;
    }
    .rwa_box{
        display: flex;
        justify-content: space-between;
        height: 680px;
    }
    
    .text_box{
        background: url(./img/text-back-about.svg) no-repeat center center;
        width: 561px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin-top: -120px;
        padding: 0 60px;
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
    }
    .btn_box{
        background: url(./img/btn_box.svg) no-repeat center center;
        width: 336px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: "Advent Pro";
        font-size: 24px;
        margin-top: 50px;
    }
    .bg_1{
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .bg_2{
        z-index: -1;
        position: absolute;
        top: 31%;
        left: 60%;
        transform: translate(-50%, -50%);
    }
    .bg_3 {
        z-index: -1;
        position: absolute;
        top: 72%;
        left: 0;
        transform: translate(-20%, -50%);
    }
    @media (max-width: 768px) {
        .rwa_box{
            flex-direction: column;
            align-items: end;
            height: 480px;
        }
        .btn_box{
            width: 240px;
            height: 60px;
            background-size: contain;
            margin: 10px 20px 0 0;
        }
        .text_box{
            width: 336px;
            height: 190px;
            font-size: 16px;
            background-size: contain;
            padding: 0 35px;
            margin:0 20px 0;
        }
        .bg_1{
            top: auto;
            bottom: 180px;
            left: 0;
            transform: none;
            width: 100%;
        }
        .bg_2,.bg_3{
            display: none;
        }
    }
}
h2{
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 57px;
    &.right{
        text-align: right;
    }
    
    &.center{
        text-align: center;
    }
    @media (max-width: 768px) {
        font-size: 26px;
        margin: 0 15px;
        line-height: 36px;
        &.right{
            text-align: center;
        }
    }
}
.projects{
    .carusel{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: flex-start;
        margin: 80px 0 200px;
    }
    .project{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        position: relative;
        .name{
            font-weight: 700;
            font-size: 40px;
            line-height: 50px;
            text-align: center;
            color: #7EE8FF;
            margin-bottom: 50px;
        }
        img {max-width: 570px;}
        .desc{
            background: url(./img/projectdesc.svg) no-repeat center center;
            width: 430px;
            height: 210px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 80%;
            margin-top: 130px;
            position: relative;
            .title{
                font-weight: 700;
                font-size: 24px;
                line-height: 30px;
                color: #FFFFFF;
                width: 280px;
                margin-bottom: 15px;
                }
            .text{
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                width: 280px;
            }
            .controls {
                position: absolute;
                top: -20px;
                right: -70px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap:80px;
                .left {
                    background: url(./img/projleft.svg) no-repeat center center;
                    width: 32px;
                    height: 45px;
                    transition: all 0.2s linear;
                    &:hover{
                        background: url(./img/projleft2.svg) no-repeat center center;
                        cursor: pointer;
                    }
                }
                .right {
                    background: url(./img/projright.svg) no-repeat center center;
                    width: 32px;
                    height: 45px;
                    transition: all 0.2s linear;
                    &:hover{
                        background: url(./img/projright2.svg) no-repeat center center;
                        cursor: pointer;
                    }
                }
            }
        }
        .line{
            background: url(./img/carusel_line.svg) no-repeat center center;
            position: absolute;
            width: 1380px;
            height: 355px;
            bottom: 220px;
        }
    }
    .direct_left,.direct_right{
        font-family: "Advent Pro";
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        width: 210px;
        height: 65px;
        padding-top: 5px;
        cursor: pointer;
        margin-top: 23%;
    }
    .direct_left{
        background: url(./img/carusel_left.svg) no-repeat left center;
        padding-left: 90px;
        color:#47EF7F;
    }
    .direct_right{
        background: url(./img/carusel_right.svg) no-repeat right center;
        padding-right: 35px;
        color: #7EE8FF;
    }
    .backgrounds {
        .bg_1{
            z-index: -1;
            position: absolute;
            width: 166px;
            height: 266px;
            left: -30%;
            top: 33%;
            background: #BA5FFF;
            filter: blur(148.85px);
        }
        .bg_2{z-index: -1;
            position: absolute;
            width: 255px;
            height: 255px;
            left: 44%;
            top: 27%;
            background: #FA5CFF;
            filter: blur(148.85px);
        }
        .bg_3{z-index: -1;
            position: absolute;
            width: 166px;
            height: 266px;
            right: -30%;
            top: -5%;
            opacity: 0.2;
            background: #7EE8FF;
            filter: blur(148.85px);
        }
        .bg_4{z-index: -1;
            background: url(./img/projline1.svg) no-repeat center center;
            position: absolute;
            width: 1027px;
            height: 330px;
            top: 23%;
            left: 0;
            transform: translate(-30%, -50%);
        }
        .bg_5{z-index: -1;
            background: url(./img/projline2.svg) no-repeat center center;
            position: absolute;
            width: 390px;
            height: 737px;
            bottom: 220px;
            left: -7%;
            transform: translate(-50%, 50%);
        }
    }
    @media (max-width: 768px) {
        margin-bottom: 120px;
        .carusel{
            margin:0;
        }

        .project {
            height: 500px;
            justify-content: flex-start;
            .name{
                font-size: 18px;
                margin-bottom: 10px;}
            img {
                max-width: 80%;
            }
            .desc{
                margin: 0;
                width: 330px;
                height: 210px;
                position: absolute;
                bottom: 10px;
                .controls{
                    gap: 30px;
                    right: 0px;
                    .left,.right{
                        width: 26px;
                        height: 38px;
                        background-size: contain;
                        &:hover{
                            background-size: contain;
                        }
                    }
                }
            }
            .line{
                width: 360px;
                height: 99px;
                bottom: -100px;
                background-size: contain;
            }
        }

        .direct_left, .direct_right,
        .backgrounds{
            display: none;
        }
    }
}
.invest{
    margin-bottom: 250px;
    h2 {
        width: 50%;
    }
    .invest_box{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .chart{}
        .calc{
            .controls {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                gap:10px;
                justify-content: center;
                height: 87px;
                font-family: "Advent Pro";
                font-weight: 700;
                font-size: 24px;
                
                .minus{
                    background: url(./img/minus.svg) no-repeat;
                    width: 40px;
                }
                .btn{
                    background: url(./img/buybtn.svg) no-repeat;
                    width: 338px;
                    height: 85px;
                    &:hover {
                        background: url(./img/buybtnhov.svg) no-repeat center center;
                    }
                }
                .plus{
                    background: url(./img/plus.svg) no-repeat;
                    width: 40px;
                }
                .minus,.plus,.btn{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.2s linear;
                    cursor: pointer;
                }
                .minus:hover,.plus:hover{color:#FA5CFF}
            }
            .text{
                width: 350px;
                font-weight: 300;
                font-size: 20px;
            }
        }
        
    }
    .backgrounds {
        .bg_1{
        z-index: -1;
        position: absolute;
        width: 166px;
        height: 166px;
        left: 5%;
        bottom: 0;
        background: #FFC176;
        filter: blur(148.85px);
        }
        .bg_2{z-index: -1;
            position: absolute;
            width: 166px;
            height: 166px;
            left: 45%;
            top: 10%;
            background: #FA5CFF;
            filter: blur(148.85px);
        }
        .bg_3{z-index: -1;
            position: absolute;
            width: 371px;
            height: 342px;
            right: -40%;
            top: -15%;
            background: #FFC176;
            opacity: 0.4;
            filter: blur(148.85px);
            transform: rotate(-180deg);
        }
    }
    @media (max-width: 768px) {
        margin: 30px 0;
        h2 {
            width: 90%;
            line-height: 40px;
        }
        .invest_box{
            flex-direction: column;
            .chart{
                display: flex;
                justify-content: center;
                margin-top: 30px;
                img {width: 80%;}
            }
            .calc{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                .text{
                    font-size: 16px;
                    margin-top: 30px;
                    text-align: center;
                }
                img {width: 80%;}
                .controls {
                    .minus,.plus{
                        width: 32px;
                        height: 66px;
                        background-size: contain;
                    }
                    .btn{
                        width: 260px;
                        height: 66px;
                        background-size: contain;
                        &:hover{
                            background-size: contain;
                        }
                    }
                }
            }
        }
        .backgrounds{
            display: none;
        }
    }
}
.press{
    background: url(./img/press.svg) no-repeat;
    background-position-y: 80px;
    background-size: 1637px;

    .forbes-container {
        margin: 0 auto;
        padding: 20px 0;
        overflow: hidden;
        display: flex;
        &.animated:hover .forbes {
            animation-play-state: paused;
        }
    }
    .forbes {
        display: flex;
        justify-content: space-around;
        gap: 20px;
        flex-wrap: nowrap;
        flex-direction: row;
        height: 211px;
        margin-top: 120px;
        padding-right: 20px;
        will-change: transform;
        &.animated {
            animation: scrolling 60s linear infinite;
        }
        .forb{
            background: url(./img/forb.svg) no-repeat;
            width: 436px;
            height: 211px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;
            .forb-url{
                font-weight: 700;
                font-size: 16px;
                color: #FA5CFF;
                letter-spacing: 0.05em;
                font-family: "Advent Pro", sans-serif;
            }
        }
    }
    @media (max-width: 768px) {
        margin: 30px 0;
        background: none;
        .forbes {
            height: 120px;
            margin-top: 20px;
            .forb{
                width: 218px;
                height: 105px;
                background-size: contain;
                img{
                    max-width: 100px;
                }
            }
        }
        .partners_box{height: 220px;}
        .backgrounds {
            .bg_1{
                max-width: 100%;
                top: 24%;
                left: auto;
                transform: unset;
            }
            .bg_2,.bg_3,.bg_4,.bg_5{display:none}
        }
    }
}
.partners{
    .partners_box{
        display: flex;
        justify-content: center;
        height: 760px;
        position: relative;
        align-items: center;
    }
    .backgrounds {
        .bg_1{
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        }
        .bg_2{z-index: -1;
            position: absolute;
            width: 166px;
            height: 166px;
            left: -200px;
            top: 300px;
            background: #FFC176;
            filter: blur(148.85px);
        }
        .bg_3{z-index: -1;
            position: absolute;
            width: 166px;
            height: 166px;
            right: 0;
            bottom: 60px;
            background: #BA5FFF;
            filter: blur(148.85px);
        }
        .bg_4{z-index: -1;
            position: absolute;
            width: 688px;
            height: 688px;
            left: 20%;
            bottom: -50%;
            display: none;
            background: #7EE8FF;
            opacity: 0.1;
            filter: blur(148.85px);
        }
        .bg_5{z-index: -1;
            position: absolute;
            width: 150px;
            height: 300px;
            right: -40%;
            top: 30%;
            background: #7EE8FF;
            filter: blur(148.85px);
        }
    }
    @media (max-width: 768px) {
        margin: 30px 0;
        .partners_box{height: 220px;}
        .backgrounds {
            .bg_1{
                max-width: 100%;
                top: 24%;
                left: auto;
                transform: unset;
            }
            .bg_2,.bg_3,.bg_4,.bg_5{display:none}
        }
    }
}
.news{
    margin-bottom: 250px;
    .news_box {
        display: flex;
        justify-content: center;
        height: 680px;
        position: relative;
        align-items: center;
    }
    .text_box{
        background: url(./img/text-back-about.svg) no-repeat center center;
        width: 561px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        position: absolute;
        right: 0;
        bottom: -140px;
        padding: 0 60px;
        font-size: 20px;
        font-weight: 300;
        animation: fadeInBg 1.4s ease-in-out forwards;
        z-index: 9;
    }
    .icons{
        position: relative;
        width: 640px;
        height: 480px;
        .wa,.tg, .fb, .in{
            position: absolute;
        }
        .wa{background: url(./img/whatsapp.svg) no-repeat center center;
            width: 168px;
            height: 171px;
        }
        .fb{background: url(./img/fb.svg) no-repeat center center;
            width: 168px;
            height: 153px;
            right: 120px;
           top: -50px;
        }
        .tg{background: url(./img/tg.svg) no-repeat center center;
            width: 149px;
            height: 113px;
            right: 80px;
            bottom: 120px;
        }
        .in{background: url(./img/inst.svg) no-repeat center center;
            width: 192px;
            height: 158px;
            bottom: -30px;
            left: 180px;
        }
    }
    .backgrounds {
        .bg_1{
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        }
        .bg_2{
            position: absolute;
            width: 166px;
            height: 166px;
            background: #FFC176;
            filter: blur(148.85px);
            top: 50%;
        }
        
        .bg_3{
            position: absolute;
            width: 234px;
            height: 234px;
            left: 55%;
            top: 43%;
            background: #FA5CFF;
            filter: blur(148.85px);
        }
    }
    @media (max-width: 768px) {
        margin-bottom: 100px;
        .news_box{
            height: 420px;
            flex-direction: column;
            justify-content: flex-start;}
        .icons{
            width: 360px;
            height: 200px;
            .wa{
                width: 88px;
                height: 88px;
                top: 40px;
                left: 50px;
                background-size: contain;}
            .fb{
                width: 88px;
                height: 88px;
                right: 30%;
                top: 10px;
                background-size: contain;}
            .tg{
                width: 80px;
                height: 80px;
                top: 85px;
                right: 60px;
                background-size: contain;}
            .in{
                width: 100px;
                height: 100px;
                left: 26%;
                bottom: -30px;
                background-size: contain;}
        }
        .backgrounds {
            .bg_1{
                max-width: 100%;
                top: 16%;
                left: auto;
                transform: unset;
            }
            .bg_2,.bg_3{display:none}
        }
        .text_box{ 
            background: url(./img/m_text-back-about.svg) no-repeat center center;
            width: 240px;
            height: 200px;
            top: auto;
            bottom: 0;
            left: 25px;
            font-size: 14px;
            padding: 0 20px;
        }
    }
}
@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity:1;
        transform: translateX(0);
    }
  }
@keyframes fadeInBg {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity:1;
        transform: translateY(0);
    }
  }
@keyframes scrolling {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
}