.profile {
    padding: 30px 0 130px;
    color: #fff;
    margin: 30px 0;
    .balance_box {
        background: url('./img/back_profile.svg') no-repeat;
        display: flex;
        align-items: center;
        position: relative;
    }
    &-img {
        margin: 10px;
    }
    .balance{
        color:#7EE8FF;
        font-size: 48px;
        font-weight: 700;
        .abb{font-size: 24px;}
    }
    
    .lines {
        .line1{
            z-index: -1;
            background: url('./img/line1.svg') no-repeat;
            position: absolute;
            width: 103px;
            height: 210px;
            top: 0;
            left: 0;
            transform: translate(-98%, -80%);
        }
        .line2 {
            background: url('./img/line2.svg') no-repeat;
            width: 40rem;
            width: 1385px;
            height: 273px;
            overflow: hidden;
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-30px, 63px);
        }
    }
    .cliff{display: none}
    .convert{
        color:#fff;
        font-size: 24px;
        font-weight: 500;
        .abb{font-size: 16px;}
    }

    h1{
        font-family: "Advent Pro", sans-serif;
        font-family: "Unbounded", sans-serif;
    }
    .wallet-address{
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        font-weight: 200;
    }

    .address-block {
        display: flex;
        gap: 16px;
        margin-top: 16px;
    }
    &-info {
        margin-bottom: 20px;

        p {
            margin: 10px 0;
        }

        .copy-button {
            padding-left: 5px;
            background-color: transparent;
            border: none;
            cursor: pointer;
        
            .icon-copy {
                color: #324e63;
                transition: color 0.3s;
        
                &:hover {
                    color: #3f6a89;
                }
            }
        }        
    }
    
    .tabs {
        width: 100%;
        margin: 50px auto;
      }
      
      .tab-buttons {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 20px;
        max-width: 60%;
      
        div{
          flex: 1;
          padding: 10px 15px 25px;
          border: none;
          cursor: pointer;
          font-size: 16px;
          transition: background 0.3s ease, color 0.3s ease;
          position: relative;
          text-align: center;
          font-weight: 300;
          text-transform: uppercase;
          letter-spacing: 1px;
      
          &.active {
            color: #FA5CFF;
            &:after {
                content: '';
                width: 100px;
                height: 4px;
                bottom: 0;
                position: absolute;
                background: #FA5CFF;
                left: 50%;
                transform: translateX(-50%);
                box-shadow: 0px 0px 8px #FA5CFF;
                border-radius: 12px;
                transition: background 0.3sease, color 0.3sease;
            }
          }
      
          &:hover {
            color: #FA5CFF;
          }
        }
      }
      
      .tab-content {
      
        .deposit, .sell, .history, .referral {
          display: none;
        }
      
        .active_tab {
          display: flex;
        }
      }
      
      .tab {
        .inputs{    display: flex;}
        input {
            box-sizing: border-box;
            background: rgba(126, 232, 255, 0.1);
            background-color: rgba(126, 232, 255, 0.1);
            border: 1px solid #5E97A3;
            backdrop-filter: blur(2px);
            width: 318px;
            height: 60px;
            display: block;
            color: #fff;
            padding: 20px;
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            outline: none;
        }
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }
        input[type="number"] {
            -moz-appearance: textfield;
        }
        .currency {
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: #6C8B92;
            margin-top: 15px;
        }
      }
      
      .deposit {
        display: flex;
        align-items: flex-start;
        .or{
            width: 128px;
            height: 58px;
            background: url('./img/or.svg') no-repeat center;
            text-align: center;
            line-height: 58px;
            font-size: 14px;
            line-height: 58px;
        }
      }
      .sell {
        .or{
            width: 20px;
        }
      }
      
    .history {
        .table1{
            display: grid;
            position: relative;
            width: 100%;
            margin-top: 50px;
            table{
                background: #19282c;
                border: none;
                border-spacing: 0;
                border-collapse: collapse;
                thead{
                background: url('./img/tab_head.svg') no-repeat center;
                background-size: cover;
                height: 51px;
                border: 0;
                    th{
                    padding: 0 30px;
                    text-align: left;
                    font-weight: 500;
                    letter-spacing: 1px;
                        &:first-child {
                            border-right: 1px solid #384b4f;
                        }
                    }
                }
                tbody{
                    td{
                    padding: 15px 30px;
                    border: 1px solid #384b4f;
                    border-top: 0;
                    font-weight: 300;
                    font-size: 14px;
                    letter-spacing: 1px;
                    }
                }
            }
            .lines{
                .line4 {
                    background: url('./img/line_tab_left.svg') no-repeat;
                    width: 165px;
                    height: 116px;
                    position: absolute;
                    top: -11px;
                    left: -18px;
                }
                .line5 {
                    background: url('./img/line_tab1_right.svg') no-repeat;
                    width: 88px;
                    height: 115px;
                    position: absolute;
                    top: -9px;
                    right: -1px;
                }
            }
        }
    }
      
    .referral {
        flex-direction: column;
        .stat{
            display: flex;
            justify-content: space-between;
            margin: 35px 0;
            &__box{
                width: 70%;
            }
            &__items{
                display: flex;
                margin-bottom: 40px;
            }
            &__item{
                width: 33%;
            }
            &__title {
                color: #7EE8FF;
                margin-bottom: 20px;
                font-size: 32px;
                font-weight: 700;
                line-height: 40px;
                span{
                    font-size: 20px;
                    line-height: 20px;
                }
            }
            &__txt{
                font-size: 12px;
                font-weight: 300;
                line-height: 18px;
                text-transform: uppercase;
            }
            &__qr{
                background: url('./img/qr2.svg') no-repeat center;
                width: 270px;
                height: 277px;
                box-sizing: border-box;
                padding: 40px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
            .invite__text{
                font-size: 14px;
                line-height: 18px;
                font-weight: 300;
                color:#6C8B92;
                margin-bottom: 10px;
            }
            .invite__url{
                background: rgba(126, 232, 255, 0.1);
                border: 1px solid #5E97A3;
                font-size: 14px;
                font-weight: 300;
                line-height: 60px;
                padding: 0 20px;
                position: relative;
                .copy{
                    background: url('./img/copy.svg') no-repeat center;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                }
            }
        }
        table{
            background: #19282c;
            border: none;
            border-spacing: 0;
            border-collapse: collapse;
            thead{
                background: url('./img/tab_head.svg') no-repeat center;
                background-size: cover;
                height: 51px;
                border: 0;
                th{
                    padding: 0 30px;
                    text-align: left;
                    font-weight: 500;
                    letter-spacing: 1px;
                    &:first-child {
                        border-right: 1px solid #384b4f;
                    }
            }
            }
            tbody{
                td{
                    padding: 15px 30px;
                    border: 1px solid #384b4f;
                    border-top: 0;
                    font-weight: 300;
                    font-size: 14px;
                    letter-spacing: 1px;
                }
            }
        }
        .table2{
            display: grid;
            position: relative;
            .lines{
                .line3{
                    z-index: -1;
                    background: url('./img/line_invite.svg') no-repeat;
                    position: absolute;
                    width: 50px;
                    height: 191px;
                    top: -119px;
                    left: -50px;
                }
                .line4 {
                    background: url('./img/line_tab_left.svg') no-repeat;
                    width: 165px;
                    height: 116px;
                    position: absolute;
                    top: -11px;
                    left: -18px;
                }
                .line5 {
                    background: url('./img/line_tab_right.svg') no-repeat;
                    width: 260px;
                    height: 111px;
                    position: absolute;
                    top: -7px;
                    right: -9px;
                }
                .line6 {
                    background: url('./img/line_tab_bottom.svg') no-repeat;
                    width: 252px;
                    height: 10px;
                    position: absolute;
                    bottom: -20px;
                    right: 0;
                }
            }
        }
        
      }
     
    @media (max-width: 768px) {
        padding: 0px 0 130px;
        .balance_box {
            background: url('./img/back_profile-m.svg') no-repeat;
            background-size: contain;
            width: 370px;
        }
        &-img img{
            width: 90px;
        }
        &-info {font-size: 14px;}
        .lines {display: none;}
        .balance{
            font-size: 22px;
            .abb{font-size: 14px;}
        }
        .convert{
            font-size: 14px;
            .abb{font-size: 12px;}
        }
        .tabs {
            margin: 20px auto
        }
        .tab-buttons {
            max-width: none;
            gap: 5px;
        }
        .tab-buttons {
            max-width: none;
            gap: 5px;
            overflow-x: auto;
            white-space: nowrap;
            padding: 10px 0;
            scrollbar-width: none;
            div{
                padding: 10px 0;
                font-size: 12px;
                flex: 0 0 auto;
                width: 100px;
                &.active{
                    &:after{
                    width: 80px;
                    height: 2px;
                }}
            }
        }
        .deposit {
            &.active_tab{
                flex-direction: column;
                position: relative;
                margin: 0 10px;
                align-items: center;
                .input {
                    margin-bottom: 25px;
                }
            }
            
        .or{
            width: 128px;
            height: 58px;
            background: url('./img/or_m.svg') no-repeat center;
            text-align: center;
            font-size: 14px;
            line-height: 58px;
            position: absolute;
            top: 60px;
            right: 0;
        }
        }
        .sell{
            &.active_tab{
                flex-direction: column;
                align-items: center;
                .input {
                    margin-bottom: 25px;
                    &:nth-child(2){
                        display: flex;
                        flex-direction: column;
                        input{order:2}
                        .currency{order:1;margin: 0 0 15px;}
                    }
                }
            }
        }
        .history{
            .table1{
                overflow-x: auto;
                white-space: nowrap;
                padding: 10px 0;
                scrollbar-width: none;
                margin-top: 0px;
                table{
                    thead{
                        th{
                            padding: 0 14px;
                            font-size: 14px;
                            border-left: 1px solid #384b4f;
                            &:last-child {
                                border-right: 1px solid #384b4f;
                            }
                        }

                    }
                }
            }
        }
        .referral{
            .stat{margin:0;flex-direction: column;align-items: center;
                .invite__url{line-height: 24px;font-size: 12px;}
            }
            .stat__box {
                display: flex;
                flex-direction: column;
                width: 96%;
            }
            .stat__items{gap: 15px;}
            .stat__item{width: 28%;
                &:nth-child(3){width: 39%;}
                }
            .stat__title{font-size: 20px;margin-bottom: 10px;
                span{font-size: 14px;line-height: 14px;}
            }
            .stat__txt{font-size: 9px;line-height: 14px;}
            .stat__qr {
                margin: 30px;
            }
            table{
                thead{}
                tbody{
                    td{
                        padding: 15px;
                    }
                }

            }
            .table2{
                overflow-x: auto;
                white-space: nowrap;
                padding: 0 10px;
                scrollbar-width: none;
                table{
                    thead{
                        th{
                            padding: 0 14px;
                            font-size: 14px;
                            &:first-child {
                                border-left: 1px solid #384b4f;
                            }
                            &:last-child {
                                border-right: 1px solid #384b4f;
                            }
                        }

                    }
                }
            }
        }

    } 
    .btn {
            background: url('./img/btn.svg') no-repeat;
            margin-left: 20px;
            padding: 18px 0;
            width: 204px;
            font-size: 20px;
            text-align: center;
            color: #000;
            border: none;
            cursor: pointer;
            display: inline-block;
            transition: all 0.2s linear;
            &:hover {
                background: url('./img/btn_hover.svg') no-repeat;
                color: #fff;
            }
            @media (max-width: 768px) {
                margin:0;
            }
    }

    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 300px;
        transform: translate(-50%, -50%);
        background-color: #222;
        color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1000;
        text-align: center;

        input {
            margin-bottom: 10px;
            padding: 10px;
            width: 160px;
            border: 1px solid #444;
            border-radius: 4px;
            background-color: #141618;
            color: #fff;
        }

        button {
            margin-right: 10px;
            padding: 10px 15px;
            font-size: 16px;
            color: #fff;
            background-color: #0f820a;
            border: none;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #333;
            }
        }

        .close-button {
            background-color: #324e63;

            &:hover {
                background-color: #3f6a89;
            }
        }
    }
    
.backgrounds {
    .bg_1{
        z-index: -1;
        position: absolute;
        background-image: radial-gradient(circle closest-side, #7ee8ff78, #b3285800);
        width: 30rem;
        height: 27rem;
        filter: blur(100px);
        overflow: hidden;
        top: -10%;
        left: -50%;
        transform: scale3d(2, 2, 1);
    }
    .bg_2 {
        z-index: -1;
        position: absolute;
        background: #FFC176;
        width: 100px;
        height: 100px;
        filter: blur(120px);
        top: -36%;
        right: -28%;
    }
    @media (max-width: 768px) {
    .bg_1 {
        width: 60vw;
        height: 54vw;
        left: -30vw;
    }
}
}
}
    
.custom-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    background-color: #141618;
    color: #fff;

    th, td {
        padding: 12px 16px;
        border: 1px solid #333;
    }

    th {
        background-color: #324e63;
        color: #fff;
        font-weight: 700;
    }

    tbody tr:nth-child(even) {
        background-color: #1e2a32;
    }

    tbody tr:hover {
        background-color: #2d3c45;
        transition: background-color 0.3s ease;
    }
}
.wallet-address {
    .address {
        cursor: pointer;
        
        &:hover {
            text-decoration: underline;
        }
    }
}

.qr-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.qr-popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
    .popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        
        h3 {
            margin: 0;
        }
        
        .close-button {
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
            padding: 0;
            margin: 0;
            line-height: 1;
        }
    }
    
    .popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .wallet-text {
            margin-top: 10px;
            word-break: break-all;
            text-align: center;
        }
    }
}
// Стили для секции статуса продажи
.sell-status-section {
    margin: 5px 0 0 20px;
    h3 {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #6C8B92;
        margin-top: 0;
    }
  }
  
  .sell-status {
    display: flex;
    align-items: center;
    .status-text {
      font-size: 14px;font-weight: 300;
    }
  }
  
  .sell-available {
    color: #0d8050;
  }
  
  
  .loading-status {
    font-size: 14px;font-weight: 300;
  }

  .status-message {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .status-message.success {
    color: #2e7d32;
  }
  
  .status-message.warning {
    color: #ff8f00;
  }
  
  
  .btn.disabled {
    cursor: not-allowed;
  }