.header {
    margin-top:40px;

.header-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url(./img/menu.svg) no-repeat;
    height: 80px;
    margin: 0 auto;
    padding: 0 28px;
    width: 1350px;
    box-sizing: border-box;
}
.header-logo {
    z-index: 1001;
}

    .header-navigation {
        display: flex;
        margin-left: -40px;
        gap: 15px;

    a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        width: 125px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        background: url(./img/link.svg) no-repeat;
        transition: background-color 0.3s;

        &:hover{
            background: url(./img/link2.svg) no-repeat;
        }
        /*&.active{
            background: url(./img/link2.svg) no-repeat;
        }*/
        &.wp{
            background: url(./img/wp.svg) no-repeat;
            width: 158px;
            &:hover{
                background: url(./img/wp2.svg) no-repeat;
            }
        }
    }
}
.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:20px;
    &.mob{display: none;}
    .lang_switcher{
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('./img/lang.svg') no-repeat center center;
        width:97px;
        height: 60px;
        font-family: 'Advent Pro', sans-serif;
        font-weight: 700;
        font-size: 20px;
        cursor: pointer;
        .lang-btn{
            img {margin-right:6px}
        }

        &.open{
         background: url('./img/lang2.svg') no-repeat center center;
         height: 87px;
         margin-top: 26px;
         .lang-btn{
            display: none;
         }
         ul{
            list-style: none;
            margin: 0;
            padding: 0;
            margin-top: 2px;
            li:first-child{margin-bottom: 5px;}
         }
        }
    }
    .profile-btn{
      display: flex;
      align-items: center;
      justify-content: center;
      
      background: url('./img/disconnect.svg') no-repeat center center;
      background-size: contain;
      width: 160px;
      height: 60px;
      cursor: pointer;
      a{
        color: #7EE8FF;
        font-family: "Advent Pro", sans-serif;
        font-weight: 700;
        font-size: 19px;
        letter-spacing: 0.05em;
        text-decoration: none;
      }

    }

    .header-button {
        background: url('./img/button.svg') no-repeat center center;
        background-size: contain;
        width: 240px;
        height: 60px;
        border: none;
        color: #7EE8FF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'Advent Pro';
        font-weight: 700;
        font-size: 19px;
        letter-spacing: 0.05em;
        &:hover {
            background: url('./img/buttonhov.svg') no-repeat center center;
        }
        &.connected{

          background: url('./img/lang.svg') no-repeat center center;
          width:97px;
          height: 60px;
          font-weight: 500;
          font-size: 16px;
        }
    }
    @media (max-width: 768px) {
        display: none;
    }
}
.connect-container {
    position: relative;
  }
  
  .connect-menu {
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
        &.open .bar:nth-child(2) {
      opacity: 0;
      
    }
  
    .bar {
      width: 100%;
      height: 3px;
      background: #fff;
      transition: transform 0.3s ease-in-out;
      &:nth-child(2){
          background: #7EE8FF;
      }
    }
  
    &.open .bar:nth-child(1) {
      transform: translateY(9px) rotate(45deg);
    }
  
    &.open .bar:nth-child(2) {
      opacity: 0;
      
    }
  
    &.open .bar:nth-child(3) {
      transform: translateY(-13px) rotate(-45deg);
    }
  }
  .menu {
    position: absolute;
    top: 60px;
    right: 0;
    background: #283d42;
    box-shadow: -1px 1px 3px rgb(126 232 255);
    width: 150px;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 9999;
  
    &.active {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }
  
    ul {
      list-style: none;
      margin: 0;
      padding: 10px;
  
      li {
        padding: 10px;
        text-align: center;
  
        a {
          text-decoration: none;
          color: #fff;
          display: block;
          transition: all 0.3s;
  
          &:hover {
            color: #7EE8FF;
          }
        }
      }
    }
  }
  
}
.hamburger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
    z-index: 1001;
  
    .bar {
      width: 30px;
      height: 3px;
      background: #fff;
      transition: 0.3s;
      &:nth-child(2){
          background: #7EE8FF;
      }
  
    &.open:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }
  
    &.open:nth-child(2) {
      opacity: 0;
    }
  
    &.open:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
}
  }
@media (max-width: 768px) {
    .header {
        margin: 20px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-box{
            background: url(./img/m_menu.svg) no-repeat;};
            max-width: 390px;
    }

    .hamburger-menu {
      display: flex;
    }
  
    .header-navigation {
      position: absolute;
      top: 0;
      left: 0;
      width: 65%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      padding: 120px 20px 20px;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      a {
        font-size: 18px;
        width: 100%;
        text-align: center;
      }
    }
    .header-navigation.open {
      transform: translateX(0);
      background: #000;
      box-shadow: 0 0px 5px #7EE8FF;
      margin: 0;
      align-items: flex-start;
      .buttons.mob{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 50px;
        .lang_switcher{
            order: 2;
            &.open{
                margin: 0;
            }
        }
        .m_menu{
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    margin-bottom: 15px;
                  a {            
                    &:hover {
                      color: #7EE8FF;
                    }
                  }
                }
              }
        }
      }
    }
}
